<template>
    <div id="app">
        <div class="flex flex-col h-screen cont">
            <header class="p-4 flex justify-between items-center">
                <div class="flex items-center">
                    <img :class="{'h-10': !isOutlook, 'h-8': isOutlook}" alt="logo" :src="`${$assetPrefix}/icon-192.png`">
                    <div class="font-bold ml-2" :class="{'text-xl': !isOutlook, 'text-lg': isOutlook}">{{ $branding.logoTitle }}</div>
                </div>
                <div v-if="!isOutlook" class="mr-6">
                    <button v-if="account" @click="logout">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-700 hover:text-black cursor-pointer" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                        </svg>
                    </button>
                </div>
            </header>
            <main class="flex-grow">
                <div v-if="loading" class="flex items-center justify-center">
                    <loader class="h-12 w-12 py-10"></loader>
                </div>

                <auth v-if="!loading && !account" @login="login" :error="error"></auth>
                <template v-if="$app === 'excel'">
                    <excel-send v-if="!loading && account" :client="client" :account="account"></excel-send>
                </template>
                <template v-if="$app === 'outlook'">
                    <outlook-app v-if="!loading && account" :client="client" :account="account" @logout="logout"></outlook-app>
                </template>
            </main>
            <footer class="px-4 py-2 text-xxs text-gray-600">
                <div v-if="account" class="mb-1">{{ account.email }}</div>
                <div class="flex justify-between">
                    <div><a target="_blank" :href="$branding.footerVersionUrl">{{ $branding.footerVersion }}</a></div>
                    <div><a target="_blank" :href="$branding.privacyUrl">Privacy Policy</a> / <a target="_blank" :href="$branding.termsUrl">Terms</a></div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import ExcelSend from '@/components/Excel/Send/Send'
import OutlookApp from '@/components/Outlook/App'
import ApiClient from '@edgility/ts-api-client'
import Auth from '@/components/Auth'
import Loader from '@/components/Util/Loader'
import { setupEcho } from '@/modules/pusher'

export default {
  name: 'App',
  components: {
    Loader,
    Auth,
    ExcelSend,
    OutlookApp,
  },
  data () {
    return {
      loading: true,
      client: null,
      error: null,
      apiKey: null,
      apiPath: null,
      account: null,
    }
  },
  methods: {
    login (apiKey) {
      this.apiKey = apiKey
      this.setHttpClient()
    },
    logout () {
      window.localStorage.removeItem('api-key')
      window.localStorage.removeItem('api-path')
      this.account = null
      this.apiKey = null
      this.apiPath = null
      this.client = null
      this.loading = false
    },
    setHttpClient (showError = true, checkWhitelabel = false) {
      let basePath = this.apiPath || process.env.VUE_APP_BASE_PATH || this.$branding.apiBase

      const touchPath = 'https://app.touchsms.com.au/api/v2'
      if (checkWhitelabel && basePath !== touchPath) {
        // If on edgility, check and see if we can use touch for WL clients
        basePath = touchPath
      }

      this.client = new ApiClient({ basePath, apiKey: this.apiKey })

      this.client.getAccount().then(res => {
        this.account = res.data

        if (this.$app === 'outlook') {
          setupEcho(this.$branding.pusherConfig || {}, basePath, this.apiKey)
        }

        window.localStorage.setItem('api-key', JSON.stringify(this.apiKey))

        if (checkWhitelabel) {
          window.localStorage.setItem('api-path', basePath)
        }
        this.loading = false
      }).catch(() => {
        if (!checkWhitelabel) {
          this.setHttpClient(showError, true)
          return
        }

        if (showError) {
          this.error = 'API Keys are invalid'
          this.logout()
        }

        // Auth is not valid
        this.client = null
        this.loading = false
      })

    },
  },
  computed: {
    isOutlook () {
      return this.$app === 'outlook'
    },
  },
  mounted () {
    const storedKey = window.localStorage.getItem('api-key')
    const apiPath = window.localStorage.getItem('api-path')

    if (!storedKey) {
      this.loading = false
      return
    }

    this.apiKey = JSON.parse(storedKey)
    this.apiPath = apiPath
    this.setHttpClient()
  },
}
</script>
<style>

</style>
