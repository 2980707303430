<template>
  <div v-if="!isMobile || currentThread" class="h-full mx-2">
    <div class="">
      <template v-if="currentThread">
        <div class="h-full msg-wrap" ref="messages">
          <infinite-loading ref="messagesLoader" direction="top" :identifier="currentThread.remote" @infinite="loadMessages">
            <div class="text-xxs text-gray-600" slot="no-more">No more messages</div>
          </infinite-loading>
          <div class="my-2" v-for="msg in messagesDecorated" :key="msg.id"
               :class="{'w-full':true, 'md:w-75': true, 'justify-end': msg.mt}">
            <div>
              <div v-if="msg.is_first_unread" class="relative mb-2">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center">
                  <span class="px-2 bg-white text-xs text-gray-500">
                    New Messages
                  </span>
                </div>
              </div>
              <div
                  v-if="msg.message"
                  v-linkified
                  class="border rounded-lg whitespace-pre-line break-words py-2 px-3 text-sm"
                  :class="{ 'bg-blue-100': msg.mo, 'bg-gray-100': msg.mt}"
              >{{ msg.message || msg.content }}
              </div>
              <div class="flex" :class="msg.message ? 'mt-2' : ''" v-if="msg.media">
                <div v-for="media in getMediaUrls(msg)" :key="media.key">
                  <a v-if="isImage(media)" target="_blank" :href="media"><img :alt="media" class="h-12 w-16 md:h-32 md:w-48 object-cover shadow rounded" :src="media"></a>
                  <a v-else target="_blank" :href="media">
                    <div class="h-20 w-30 md:h-32 md:w-48 flex justify-center items-center text-4xl text-gray-400 bg-white shadow rounded" >
                      <span>{{ media.split('.').length > 1 ? media.split('.').pop() : '???' }}</span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="text-xxs text-gray-600 mx-1" :class="{'text-right': msg.mt}">
                <span v-if="msg.status">{{ msg.status_description }} • </span>
                {{ formatDateMessage(msg.date) }}
                <template v-if="msg.direction === 'outbound'">
                  <tippy class="status-tooltip">
                    <template v-slot:trigger>
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </template>

                    <div class="status-tooltip-content">
                      <b>Sender Id</b> {{ msg.from === 'SHARED_NUMBER' ? 'Shared Number' : msg.from }}
                    </div>
                  </tippy>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="currentThread" class="text-center">
        <i class="fa fa-spin fa-refresh"></i>
      </div>
      <div v-else class="text-center no-chats">
        Whoops, no inbound messages found. Click on New to start a conversation.
      </div>
    </div>
  </div>
</template>

<script>

import InfiniteLoading from '@atymic/vue-infinite-loading'
import { differenceInDays, format, formatRelative, parseISO } from 'date-fns'

export default {
  name: 'ChatMessages',
  components: {
    InfiniteLoading,
  },
  props: {
    client: Object,
    isMobile: Boolean,
    currentThread: Object,
  },
  data () {
    return {
      messages: [],
      messagesCursor: null,
    }
  },
  methods: {
    loadMessages ($state) {
      this.client.getConversationMessages(this.currentThread.id, { cursor: this.messagesCursor || '' }).
          then((data) => {
            if (data.data.length) {
              this.messagesCursor = data.meta.cursor
              this.messages.unshift(...data.data.reverse())

              let firstUnread = this.messages.find(m => m.is_unread)

              if (firstUnread) {
                firstUnread.is_first_unread = true;
              }

              this.$emit('messages', this.messages)
              $state.loaded()
            } else {
              $state.complete()
              this.$emit('loaded')
            }
          })
    },
    formatDateMessage (date) {
      date = parseISO(date)

      return Math.abs(differenceInDays(date, new Date())) < 6
          ? formatRelative(date, new Date(), { weekStartsOn: 1 })
          : format(date, `dd/MM/yyyy • h:mm aaa`)
    },
    scrollToLastMessage () {
      this.$refs.messages.scrollIntoView(false);
    },
    handleSent (msg) {
      this.messages.push(msg)
      this.$nextTick(() => {
        this.scrollToLastMessage()
      })
    },
    reset() {
      this.messages = []
      this.messagesCursor = null

      if (this.$refs.messagesLoader) {
        this.$refs.messagesLoader.stateChanger.reset()
      }
    },
    isImage(url) {
      return url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg');
    },
    getMediaUrls(message) {
      if (!message.media?.length) {
        return []
      }

      return message.direction === 'inbound' ? message.media.map((m) => m.url) : message.media
    },
  },
  computed: {
    messagesDecorated () {
      return this.messages.map(message => {
        const isOutBound = message.direction === 'outbound'

        return {
          ...message,
          mt: isOutBound,
          mo: !isOutBound,
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
