<template>
  <div class="mx-auto w-full max-w-sm">
    <div class="px-2">
      <div>
        <div class="text-sm text-gray-600 leading-normal">
          <p class="mb-4">Welcome to {{ $branding.name }}</p>
          <p v-if="$app === 'outlook'" class="mb-4">{{ $branding.logoTitle }} for Outlook Add-in will allow you to send SMS (text messages) directly from Outlook, without the need to leave your email environment or export data into another program. Simply click on the {{ $branding.logoTitle }} icon on your toolbar to start a conversation.</p>
          <p class="mb-4"><a :class="$linkClass" target="_blank"
                             href="https://platform.touchsms.com.au/login">Login</a> to {{ $branding.name }} to access your <a :class="$linkClass"
                                                                                                                   target="_blank"
                                                                                                                   :href="`${$branding.platformBaseUrl}/settings/api`">API
            Keys</a> in Settings.
            Create an API Key, then copy/paste your Access Token and Token ID below.</p>
          <p class="mb-1">Not signed up? Click <a :class="$linkClass" target="_blank"
                                                  :href="$branding.registerUrl">here</a> to register to <a
              :class="$linkClass" target="_blank"
              :href="$branding.registerUrl">{{ $branding.name }}</a>.</p>
        </div>

        <div class="mt-6 relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-500">
                Authenticate
              </span>
          </div>
        </div>
      </div>

      <div v-if="error" class="rounded-md bg-red-50 text-red-500 text-sm p-4 my-4">
        {{ error }}
      </div>

      <div class="mt-6 text-sm">
        <form @submit.prevent="login">
          <div>
            <label for="access_token" class="block text-sm font-medium leading-5 text-gray-700">
              Access Token
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input v-model.trim="token.accessToken" id="access_token" type="text" required
                     class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
          </div>

          <div class="mt-4">
            <label for="token_id" class="block text-sm font-medium leading-5 text-gray-700">
              Token ID
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input v-model.trim="token.tokenId" id="token_id" type="text" required
                     class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
          </div>

          <div v-if="$app === 'outlook'" class="mt-4">
            <label for="token_id" class="block text-sm font-medium leading-5 text-gray-700">
              Reference <span class="text-xs text-gray-500 font-light">Optional, for reporting</span>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input v-model.trim="reference" id="token_id" type="text"
                     class="appearance-none block w-full px-2 py-1 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
          </div>

          <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <button type="submit"
                        :class="`w-full flex justify-center py-2 px-3 border border-transparent text-sm font-medium rounded-sm text-white bg-${$branding.loginButtonClass} hover:bg-${$branding.loginButtonClass}-hvr focus:outline-none focus:shadow-outline-brand active:bg-${$branding.loginButtonClass}-hvr transition duration-150 ease-in-out`">
                  Connect
                </button>
              </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',
  props: {
    error: String,
  },
  data () {
    return {
      token: {
        accessToken: null,
        tokenId: null,
      },
      reference: null,
    }
  },
  methods: {
    login() {
      if (this.reference) {
        window.localStorage.setItem('outlook-ref', this.reference)
      }

      this.$emit('login', this.token)
    }
  }
}
</script>

<style scoped>

</style>
