<template>
  <div class="h-full mx-4">
    <div class="flex justify-between ">
      <div class="text-gray-700 flex items-center">
        Disclaimer
      </div>
      <div class="mt-1 flex rounded-md shadow-sm">
        <button @click="$emit('logout')"
                class="-ml-px relative inline-flex items-center rounded px-2 py-1 border border-gray-300 text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="prose prose-sm mt-4">
      <p>
        You will only be shown this message once per device. You agree all future messages will comply with the following;
      </p>
      <p>
        I have read and understand the <a :href="$branding.privacyUrl">terms of use</a> and agree to comply with the current Spam Act.
      </p>

      <div class="clearfix"></div>

      <div id="terms-of-use-details" style="display: block;">
        <p>The three key steps you should follow are:</p>

        <p>
        </p>
        <p><strong>Consent</strong> - Only send commercial electronic messages with the addressee's consent - either express or inferred consent</p>
        <p><strong>Identification</strong> - Include clear and accurate information about the person or business that is responsible for sending the commercial
          electronic message</p>
        <p><strong>Unsubscribe</strong> - Ensure that a functional unsubscribe facility is included in all your commercial electronic messages. Deal with
          unsubscribe requests promptly</p>

      </div>
      <label class="inline-flex items-center text-sm mb-2">
        <input v-model="terms" type="checkbox" class="form-checkbox">
        <span class="ml-2 text-gray-600 text-xs">I agree to the {{ $branding.name }} <a class="text-blue-500 hover:text-blue-700" target="_blank"
                                                                                        :href="$branding.termsUrl">Terms and Conditions</a></span>
      </label>
      <div class="w-full mb-2 mt-4">
        <button @click="$emit('agree')"  type="button" :disabled="!terms" class="w-full disabled:bg-primary-btn disabled:opacity-50 focus:outline-none text-white text-xs py-2 px-3 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Disclaimer',
  data () {
    return {
      terms: false,
    }
  },
}
</script>
