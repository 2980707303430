<template>
  <div>
    <div class="flex justify-center my-8" v-if="sending">
      <svg-loader class="h-16 w-16 text-brand-600"></svg-loader>
    </div>
    <div v-if="sending" class="rounded-md bg-yellow-100 text-yellow-500 text-sm p-4 my-4">
      Sending in progress. Do not close this panel or quit excel, as your campaign may be partially sent
    </div>
    <div v-else-if="!failed" class="flex justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-32 w-32 text-primary-btn" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <div v-if="!failed" class="my-8">
      <div class="flex justify-between px-1">
        <p class="font-semibold">Sent Messages</p>
        <p> {{ stats.totalMessages }} / {{ messages.length }}</p>
      </div>
      <template v-if="errors.length">
        <hr class="my-4 mx-2">
        <div class="flex justify-between px-1">
          <p class="font-semibold">Errors</p>
          <div v-if="sending">
            <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
          </div>
          <div class="text-red-500" v-else>{{ this.errors.length }}</div>
        </div>
      </template>
      <hr class="my-4 mx-2">
      <div class="flex justify-between px-1">
        <p class="font-semibold">Total Cost <span class="font-normal text-gray-500">(credits)</span></p>
        <div v-if="sending">
          <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
        </div>
        <div v-else>{{ this.stats.totalCredits.toFixed(1) }}</div>
      </div>
    </div>
    <div class="rounded-md bg-red-100 text-red-500 text-sm p-4 my-4" v-else>
      Failed to send messages. Please contact us.
    </div>
    <div v-if="!sending" class="my-4">
      <div class="mr-2 mt-2">
        <a target="_blank" :href="`${$branding.platformBaseUrl}/reports/campaigns/${campaignId}`"
           class="block w-full focus:outline-none text-white text-center text-xs py-2 px-5 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr disabled:bg-primary-btn disabled:opacity-50 ">
          View Campaign Report
        </a>
      </div>
      <div @click="$emit('back')" class="mr-2 mt-2">
        <button type="button" class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-5 rounded-sm bg-gray-200 hover:bg-gray-300 hover:shadow-lg">
          New Campaign
        </button>
      </div>
    </div>
    <div v-if="errorTypes">
      <p class="text-sm text-gray-600 mb-4">Errors</p>
      <div class="flex justify-between" v-for="(count, code) in errorTypes" :key="code">
        <div class="text-gray-700">{{ code }}</div>
        <div class="text-red-500 text-bold"> {{ count }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgLoader from '@/components/Util/SvgLoader'
import { camelCase, chunk, groupBy, startCase, transform } from 'lodash'
import { Promise } from 'bluebird'

export default {
  name: 'Step4',
  components: { SvgLoader },
  props: {
    messages: Array,
    client: Object,
    removeDuplicates: Boolean,
  },
  data () {
    return {
      sending: true,
      failed: false,
      campaignId: null,
      stats: {
        totalParts: 0,
        totalCredits: 0,
        totalMessages: 0,
      },
      errors: [],
    }
  },
  methods: {
    async send () {
      try {
        await Promise.map(chunk(this.messages, 1000), async msgChunk => {
          const res = await this.client.sendSms(msgChunk)

          if (res.data.messages.length) {
            this.campaignId = res.data.messages[0].campaign_id
          }

          this.stats.totalParts += res.data.meta.totalParts
          this.stats.totalCredits += res.data.meta.totalCredits
          this.stats.totalMessages += res.data.meta.totalMessages
          this.errors = this.errors.concat(res.data.errors)
        }, { concurrency: 2 })
      } catch (e) {
        console.log(e)
        this.failed = true;
      }



      this.sending = false
    },
  },
  computed: {
      errorTypes() {
        if (!this.errors.length) {
          return
        }

        return transform(groupBy(this.errors, 'error_code'), (totals, errors, type) => {
          totals[startCase(camelCase(type))] = errors.length
        }, {})
      }
  },
  mounted () {
    this.send()
  }
}
</script>

<style scoped>

</style>
