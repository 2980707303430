<template>
  <div class="h-full mx-4">
    <template v-if="!preview && !sent">
      <div class="flex justify-between ">
        <div class="text-gray-700 flex items-center">
          Send Campaign
        </div>
        <div class="mt-1 flex rounded-md shadow-sm">
          <button @click="$emit('back')"
                  class="-ml-px relative inline-flex items-center rounded px-2 py-1 border border-gray-300 text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="mt-4 text-sm">
        <label class="w-full block">
          <span class="text-gray-700 mb-2">Recipients</span>
          <recipient-multi-selector
              v-model="to"
              :recipients="[]"
              :client="client"
          >
          </recipient-multi-selector>
        </label>
      </div>
      <div class="flex text-sm mt-4">
        <label class="w-full block">
          <span class="text-gray-700">Campaign Name</span>
          <input v-model="campaign" class="form-input form-input-sm mt-1 block w-full" placeholder="Optional">
        </label>
      </div>
      <div class="mt-4">
        <label class="block text-sm">
          <span class="text-gray-700">From</span>
          <select v-model="from" class="form-select form-select-sm mt-1 block w-full">
            <option v-for="sender in senderIds" :key="sender.sender_id" :value="sender.sender_id">{{ sender.label }}</option>
          </select>
        </label>
      </div>
      <div class="mt-4">
        <div>
          <div class="h-8 rounded-t flex px-2 items-center justify-between bg-gray-100">
            <div class="text-gray-700 text-sm">Message</div>
            <div class="relative inline-block text-sm" v-on-clickaway="hideTemplateDropdown">
              <button @click="templateDropdown = !templateDropdown"
                      class="-ml-px relative inline-flex items-center rounded text-gray-700 hover:text-gray-500 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                Templates
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
              </button>
              <div
                  :class="`z-10 ${!templateDropdown ? 'hidden' : ''} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg transition ease-${templateDropdown ? 'out' : 'in'} duration-${templateDropdown ? '100' : '75'} transform opacity-${templateDropdown ? '100' : '0'} scale-${templateDropdown ? '100' : '95'}`">
                <div class="rounded-md bg-white shadow-xs">
                  <div class="py-1 max-h-48 overflow-y-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button v-for="t in templates" :key="t.id" @click="applyTemplate(t)"
                            class="block w-full text-left px-3 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem">
                      {{ t.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <textarea ref="message" v-model="message" class="form-textarea form-textarea-sm rounded-t-none block w-full" rows="6"
                    placeholder="Enter your message."></textarea>
        </div>
        <div class="relative pt-1">
          <div class="overflow-hidden h-1 mb-1 text-xs flex rounded bg-green-200">
            <div :style="{width: `${messageLength.inCurrentMessage / messageLength.characterPerMessage * 100}%`, transition:'width 0.5s ease-out'}"
                 class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
          </div>
        </div>
        <div class="flex justify-between text-xs text-gray-600">
          <div>{{ messageLength.length }} Characters</div>
          <div>{{ messageLength.messages }} Message Part</div>
        </div>
        <div class="mt-4 flex items-center justify-between flex-wrap">
          <div class="inline-block flex-none mr-1 mb-2">
            <a :href="$branding.loginUrl" target="_blank"
               class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-3 rounded-sm bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100">
              {{ $branding.loginButtonText }}
            </a>
          </div>
          <div @click="previewSend" class="inline-block flex-none mb-2">
            <button type="button" class="focus:outline-none text-white text-xs py-2 px-3 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr">Preview
              Campaign
            </button>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="preview && !sent">
      <h3 class="font-semibold text-gray-700">Here's your SMS Campaign Summary</h3>
      <p class="text-gray-700 text-xs mt-1">Click <b>Send Now</b> to send your campaign.</p>
      <div v-if="!insufficientCredits" class="my-6">
        <div class="flex justify-between px-1">
          <p class="font-semibold">Recipients</p>
          <div v-if="loading">
            <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
          </div>
          <div v-else>{{ summary.recipients }}</div>
        </div>
        <template v-if="summary && (summary.duplicates || summary.optOuts || summary.invalid)">
          <hr class="my-3 mx-2">
          <div class="flex justify-between px-1">
            <p class="font-semibold">Excluded Numbers</p>
            <div v-if="loading">
              <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
            </div>
            <div class="text-red-500" v-else>{{ summary.duplicates + summary.optOuts + summary.invalid }}</div>
          </div>
        </template>
        <hr class="my-3 mx-2">
        <div class="flex justify-between px-1">
          <p class="font-semibold">Message Length (Parts)</p>
          <div v-if="loading">
            <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
          </div>
          <div>{{ summary.messageLength }}</div>
        </div>
        <div class="mb-3 text-xxxs mx-1 text-gray-500">
          * Estimate. Merge fields may change length of message
        </div>
        <hr class="my-3 mx-2">
        <div class="flex justify-between px-1">
          <p class="font-semibold">Total Cost <span class="font-normal text-gray-500">(credits)</span></p>
          <div v-if="loading">
            <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
          </div>
          <div v-else>{{ summary.cost.toFixed(1) }}</div>
        </div>
      </div>
      <div class="rounded-md bg-red-100 text-red-500 text-sm p-4 my-4" v-else-if="failed">
        Failed to calculate messages. Please contact us.
      </div>
      <div class="rounded-md bg-yellow-100 p-4 mt-4" v-else-if="insufficientCredits">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm leading-5 font-medium text-yellow-800">
              Insufficient Credits
            </h3>
            <div class="mt-2 text-sm leading-5 text-yellow-700">
              <p>
                <a class="font-semibold hover:underline" target="_blank" href="https://platform.touchsms.com.au/purchases/">Click here</a> to top up your
                account
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!insufficientCredits" class="my-4">
        <div class="flex items-center justify-between" v-if="!failed && !insufficientCredits">
          <div class="mr-2 inline-block w-full">
            <button @click="send" :disabled="loading" type="button"
                    class="block w-full focus:outline-none text-white text-xs py-2 px-5 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr disabled:bg-primary-btn disabled:opacity-50 ">
              Send Now
            </button>
          </div>
        </div>
        <div @click="preview = false" class="mr-2 mt-2">
          <button type="button"
                  class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-5 rounded-sm bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100">
            Back to Edit
          </button>
        </div>
      </div>
      <div v-if="summary && (summary.duplicates || summary.optOuts || summary.invalid)">
        <p class="text-xs font-semibold text-gray-500 mb-4 underline">Excluded Numbers</p>
        <div class="flex justify-between" v-for="error in errorTypes.filter(t => summary[t.key] > 0 )" :key="error.key">
          <div class="text-gray-700 text-sm">{{ error.name }}</div>
          <div class="text-red-500 text-bold"> {{ summary[error.key] }}</div>
        </div>
      </div>
    </template>
    <template v-if="sent">
      <div class="flex justify-center my-8" v-if="loading">
        <svg-loader class="h-16 w-16 text-brand-600"></svg-loader>
      </div>
      <template v-else>
        <div class="flex items-center justify-center mb-8">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-32 w-32 text-primary-btn" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
        </div>
        <div v-if="this.reportUrl" class="mr-2 mt-2">
          <a target="_blank" :href="reportUrl"
             class="block w-full focus:outline-none text-white text-center text-xs py-2 px-5 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr disabled:bg-primary-btn disabled:opacity-50 ">
            View Campaign Report
          </a>
        </div>
        <div @click="reset" class="mr-2 mt-2">
          <button type="button"
                  class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-5 rounded-sm bg-gray-200 hover:bg-gray-300 hover:shadow-lg">
            New Campaign
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { count } from 'sms-length'
import { mixin as clickaway } from 'vue-clickaway'
import SvgLoader from '@/components/Util/SvgLoader'
import RecipientMultiSelector from '@/components/Outlook/Campaign/Components/RecipientMultiSelector'

export default {
  name: 'Campaign',
  components: {
    RecipientMultiSelector,
    SvgLoader,
  },
  mixins: [clickaway],
  data () {
    return {
      to: [],
      campaign: null,
      from: null,
      message: '',
      template: null,

      broadcast: null,
      summary: null,

      preview: false,
      sent: false,
      loading: false,
      insufficientCredits: false,
      failed: false,
      stats: null,
      parts: null,
      errors: [],
      reportUrl: null,

      senderIds: [],
      templates: [],

      showNumber: null,
      pasting: false,
      toSelectOpts: {
        mode: 'tags',
        value: [],
        searchable: true,
        createTag: true,
        options: async (query) => {
          return await this.searchContacts(query)
        },
      },
      templateDropdown: false,
      errorTypes: [
        { name: 'Duplicates', key: 'duplicates' },
        { name: 'Opt Outs', key: 'optOuts' },
        { name: 'Invalid Numbers', key: 'invalid' },
      ],
    }
  },
  props: {
    primaryBtnClass: String,
    secondaryBtnClass: String,
    highlightColor: String,
    client: Object,
  },
  methods: {
    previewSend () {
      this.preview = true
      this.loading = true
      this.client.request('broadcast/quick-send/preview', {
        method: 'POST',
        data: {
          to: this.to,
          sender_id: this.from,
          body: this.message,
          campaign: this.campaign || null,
          campaign_prefix: 'Outlook SMS',
          metadata: this.getMessageMeta()
        },
      }).then(res => {
        this.summary = res.summary
        this.broadcast = res.broadcast
        this.loading = false
      }).catch(err => {
        if (err.response?.status === 406) {
          this.insufficientCredits = true
          this.loading = false
        }
      })
    },
    send () {
      this.preview = false
      this.sent = true
      this.loading = true

      this.client.request(`broadcast/quick-send/${this.broadcast.id}`, { method: 'POST' }).then(res => {
        this.loading = false
        this.reportUrl = res.report_url
      })
    },
    reset () {
      this.sent = false
      this.preview = false
      this.to = []
      this.message = ''
    },
    toPaste (e) {
      this.pasting = true
      const values = e.clipboardData.getData('text').replace(/[^0-9\n]/g, '').split(/\n/)
      values.map(v => v.split(',')).flat().filter(v => v.match(/[\d ]{5,20}/)).map(v => {
        this.$refs.toSelect.select({
          label: v.replaceAll(' ', ''),
          value: v.replaceAll(' ', ''),
        })
      })
    },
    toSearchChange () {
      if (this.pasting !== true) return

      this.$refs.toSelect.clearSearch()
      this.$refs.toSelect.close()
      this.pasting = false
    },
    toSelect (e) {
      if (!String(e).match(/^[\d ]{5,20}$/)) {
        this.$refs.toSelect.deselect({ value: e })
      }
    },
    toTag (e) {
      console.log(e)
    },
    applyTemplate (template) {
      this.hideTemplateDropdown()
      const sender = this.senderIds.find(s => s.sender_id === template.sender_id)

      if (sender) {
        this.from = sender.sender_id
      }

      this.message = template.message
      this.$refs.message.focus()
    },
    hideTemplateDropdown () {
      this.templateDropdown = false
    },
    searchContacts (search) {
      return this.client.getContacts({ search }).then(data => {
        return data.data.map(c => ({ label: `${c.firstname || ''} ${c.surname || ''} (${c.number})`, value: c.number }))
      })
    },
    getMessageMeta() {
      const ref = window.localStorage.getItem('outlook-ref')

      return ref ? {reference: ref} : null
    }
  },
  computed: {
    messageLength () {
      return count(this.message)
    },
  },
  watch: {
    template (template) {
      if (!template) return

      this.applyTemplate(template)
      this.template = null
    },
  },
  created () {
    this.client.getSenderIds().then((data) => {
      this.senderIds = data.data
      this.from = this.senderIds.find(s => s.default).sender_id
    })

    this.client.getTemplates().then((data) => {
      this.templates = data.data
    })
  },
}
</script>
