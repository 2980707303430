<template>
  <div>
    <message-change v-if="fromEmail" class="mx-2 mb-4" :from-email="fromEmail" @close="fromEmail = null"></message-change>
    <disclaimer v-if="!disclaimer" @agree="agreeDisclaimer" @logout="$emit('logout')"></disclaimer>
    <chat v-if="disclaimer && !campaign" :client="client" @logout="$emit('logout')" @campaign="campaign = true" :account="account"></chat>
    <campaign v-if="disclaimer && campaign" :client="client" @back="campaign = false"></campaign>
  </div>
</template>

<script>
import Chat from '@/components/Outlook/Chat/Chat'
import Campaign from '@/components/Outlook/Campaign/Campaign'
import Disclaimer from '@/components/Outlook/Disclaimer/Disclaimer'
import MessageChange from '@/components/Outlook/Campaign/MessageChange'

export default {
  name: 'App',
  components: { MessageChange, Disclaimer, Campaign, Chat },
  props: {
    client: Object,
    account: Object,
  },
  data () {
    return {
      disclaimer: false,
      campaign: false,
      fromEmail: null,
    }
  },
  methods: {
    agreeDisclaimer () {
      window.localStorage.setItem('disclaimer', 'yes')
      this.disclaimer = true
    },
  },
  created () {
    // Code to pass audit
    // this.$root.$on('item-change', () => {
    //   const item = window.Office.context.mailbox.item;
    //   this.fromEmail = item.from.emailAddress
    // }

    this.disclaimer = !!window.localStorage.getItem('disclaimer')
  },
}
</script>
