<template>
  <multi-select
      class="multiselect-sm"
      v-model="to"
      v-bind="toSelectOpts"
      ref="toSelect"
      track-by="label"
      value-prop="data"
      @paste="toPaste"
      @option="toTag"
      @search-change="toSearchChange"
      :append-new-tag="false"
      :add-tag-on="['enter', 'tab']"
      @select="toSelect"
      :can-clear="false"
      :delay="100"
      :filterResults="false"
  ></multi-select>
</template>

<script>
import { computed } from '@vue/composition-api'
import MultiSelect from '@vueform/multiselect/dist/multiselect.vue2.js'

export default {
  name: 'RecipientMultiSelector',
  props: {
    modelValue: Array,
    recipients: Array,
    client: Object
  },
  components: { MultiSelect },
  setup (props, { emit }) {
    const to = computed({
      get: () => props.modelValue,
      set: (value) => emit('input', value),
    })

    return { to }
  },
  data () {
    return {
      toSelectOpts: {
        mode: 'tags',
        value: [],
        searchable: true,
        createTag: true,
        options: async (query) => {
          const data = await this.searchContacts(query)
          return [...this.recipientOptions, ...data]
        },
      },
    }
  },
  mounted () {
    this.recipientOptions.map(opt => this.$refs.toSelect.select(opt))
  },
  computed: {
    recipientOptions () {
      return this.recipients.map(number => ({label: number, data: { type: 'number', number }}))
    }
  },
  methods: {
    searchContacts (q) {
      return this.client.request('util/recipient-search', {
        params: { q, with_groups: 1 }
      })
    },
    toPaste (e) {
      this.pasting = true
      const values = e.clipboardData.getData('text').replace(/[^0-9\n]/g, '').split(/\n/)
      values.map(v => v.split(',')).flat().filter(v => v.match(/[\d ]{5,20}/)).map(v => {
        this.$refs.toSelect.select({label: v.replaceAll(' ', ''), data: { type: 'number', number: v.replaceAll(' ', '') }})
      })
    },
    toSearchChange () {
      if (this.pasting !== true) return

      this.$refs.toSelect.clearSearch()
      this.$refs.toSelect.close()
      this.pasting = false
    },
    toSelect (e) {
      if (typeof e === 'object' && e.type)  return;
      if (!String(e).match(/^[\d ]{5,20}$/)) {
        this.$refs.toSelect.deselect({ label: e, data: e })
      }
    },
    toTag (e) {
      console.log(e)
    },
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-sm {
  --ms-py: 0.4rem;
  --ms-px: 0.7rem;
  --ms-option-font-size: 0.7rem;
  --ms-tag-font-size: 0.5rem;
  --ms-tag-line-height: 1.1rem;
  --ms-tag-font-weight: 600;
  --ms-tag-py: 0.05rem;
  --ms-tag-px: 0.3rem;
  --ms-tag-my: 0.2rem;
  --ms-tag-mx: 0.2rem;
}

.multiselect-sm .multiselect-no-options {
  display: none;
}
</style>

