<template>
  <div v-if="currentThread" class="flex justify-between mx-4 pb-4">
    <div class="text-gray-700 flex items-center">
        <span v-if="currentThread && currentThread.contact && validContact(currentThread.contact)">
          {{ currentThread.contact.firstname }} {{ currentThread.contact.surname }}
        </span>
      <span v-else-if="currentThread">
          {{ currentThread.remote }}
        </span>
    </div>
    <div class="mt-1 flex rounded-md shadow-sm">
      <button v-if="currentThread && isNumericSource(currentThread.remote) && !currentThread.contact" @click="$emit('addContact', currentThread.remote)"
              class="-ml-px relative inline-flex items-center px-2 py-1 rounded-l border border-gray-300 text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"/>
        </svg>
      </button>
      <template v-if="isMobile">
        <button @click="$emit('back')"
                :class="{'rounded-l': !currentThread || !isNumericSource(currentThread.remote) || currentThread.contact}"
                class="-ml-px relative inline-flex items-center rounded-r px-2 py-1 border border-gray-300 text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
          </svg>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { isNumericSource, validContact } from '@/outlook/util/chat'

export default {
  name: 'ChatMessagesHeader',
  props: {
    isMobile: Boolean,
    currentThread: Object,
  },
  methods: {
    isNumericSource,
    validContact,
  }
}
</script>
