<template>
  <div>
    <div v-if="currentThread" class="flex justify-between">
      <div class="text-gray-700 flex items-center">
        Add Contact
      </div>
      <div class="mt-1 flex rounded-md shadow-sm">
        <button @click="$emit('back')"
                class="-ml-px relative inline-flex items-center rounded px-2 py-1 border border-gray-300 text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
    <validation-errors class="mt-4" :errors="errors"></validation-errors>
    <div>
      <div class="text-sm mt-4">
        <label class="w-full block">
          <span class="text-gray-700">Firstname</span>
          <input v-model="form.firstname" class="form-input form-input-sm mt-1 block w-full" placeholder="Firstname">
        </label>
      </div>
      <div class="text-sm mt-4">
        <label class="w-full block">
          <span class="text-gray-700">Surname</span>
          <input v-model="form.surname" class="form-input form-input-sm mt-1 block w-full" placeholder="Surname">
        </label>
      </div>
      <div class="text-sm mt-4">
        <label class="w-full block">
          <span class="text-gray-700">Email</span>
          <input v-model="form.email" type="email" class="form-input form-input-sm mt-1 block w-full" placeholder="email@example.com">
        </label>
      </div>
      <div class="text-sm mt-4">
        <label class="w-full block">
          <span class="text-gray-700">Phone Number</span>
          <input v-model="form.number" class="form-input form-input-sm mt-1 block w-full" placeholder="0412345678">
        </label>
      </div>
      <div class="text-sm mt-4">
        <div @click="save" class="block flex-none mt-2">
          <button type="button" class="w-full focus:outline-none text-white text-xs py-2 px-3 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr">
            Create Contact
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrors from '@/components/Util/ValidationErrors'

export default {
  name: 'AddContact',
  components: { ValidationErrors },
  props: {
    client: Object,
    currentThread: Object,
  },
  data () {
    return {
      form: {
        firstname: null,
        surname: null,
        email: null,
        number: this.currentThread.remote,
      },
      loading: false,
      errors: null,
    }
  },
  methods: {
    save() {
      this.loading = true
      this.client.createContact(this.form).then(res => {
        this.$emit('created', res.data)
      }).catch(e => {
        this.errors = e.response.data.errors
        this.loading = false
      })
    }
  },
}
</script>

<style scoped>

</style>
