<template>
  <div v-if="open" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500 text-sm">
    <div class="mb-2">
      <b>{{ title || 'There were errors' }}</b>
    </div>
    <p class="mb-1" v-for="errs in errors" :key="errs.key">{{ overrideMessage(errs[0]) }}</p>
    <button class="absolute bg-transparent text-xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            v-on:click="closeAlert()">
      <span>×</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ValidationErrorsAlert',
  props: {
    title: String,
    errors: Object,
  },
  data () {
    return {
      alertOpen: true,
      overrides: {
        'RECIPIENT_OPTED_OUT': 'Oops! Looks like this person has elected to opt out of further communication with this account. Sorry we can’t deliver this message.',
      },
    }
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false
    },
    overrideMessage (msg) {
      if (this.overrides[msg]) {
        return this.overrides[msg]
      }

      return msg
    },
  },
  watch: {
    errors () {
      this.alertOpen = true
    },
  },
  computed: {
    open () {
      return this.errors && Object.values(this.errors).length > 0 && this.alertOpen
    },
  },
}
</script>
