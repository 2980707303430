<template>
  <div>
    <h3 class="font-semibold text-gray-700">Here's your SMS Campaign Summary</h3>
    <p class="text-gray-700 text-xs mt-1">Click <b>Send Now</b> or <b>Schedule SMS</b> to send your campaign later.</p>
    <div v-if="!failed && !insufficentCredits" class="my-6">
      <div class="flex justify-between px-1">
        <p class="font-semibold">Detected Numbers</p>
        <p>{{ messages.length }}</p>
      </div>
      <template v-if="errors.length">
        <hr class="my-3 mx-2">
        <div class="flex justify-between px-1">
          <p class="font-semibold">Excluded Numbers</p>
          <div v-if="loading">
            <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
          </div>
          <div class="text-red-500" v-else>{{ this.errors.length }}</div>
        </div>
      </template>
      <hr class="my-3 mx-2">
      <div class="flex justify-between px-1">
        <p class="font-semibold">Total Unique Recipients</p>
        <div v-if="loading">
          <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
        </div>
        <div v-else>{{ this.stats.totalMessages }}</div>
      </div>
      <hr class="my-3 mx-2">
      <div class="flex justify-between px-1">
        <p class="font-semibold">Message Length (Parts)</p>
        <div v-if="loading">
          <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
        </div>
        <div v-else-if="processedMessages.length">{{ processedMessages[0].meta.parts }}</div>
      </div>
      <div class="mb-3 text-xxxs mx-1 text-gray-500">
        * Estimate. Merge fields may change length of message
      </div>
      <hr class="my-3 mx-2">
      <template v-if="schedule">
        <div class="flex justify-between px-1">
          <p class="font-semibold">Schedule Date</p>
          <div>{{ scheduleFormatted }}</div>
        </div>
        <hr class="my-3 mx-2">
      </template>
      <div class="flex justify-between px-1">
        <p class="font-semibold">Total Cost <span class="font-normal text-gray-500">(credits)</span></p>
        <div v-if="loading">
          <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
        </div>
        <div v-else>{{ this.stats.totalCredits.toFixed(1) }}</div>
      </div>
    </div>
    <div class="rounded-md bg-red-100 text-red-500 text-sm p-4 my-4" v-else-if="failed">
      Failed to calculate messages. Please contact us.
    </div>
    <div class="rounded-md bg-yellow-100 p-4 mt-4" v-else-if="insufficentCredits">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm leading-5 font-medium text-yellow-800">
            Insufficient Credits
          </h3>
          <div class="mt-2 text-sm leading-5 text-yellow-700">
            <p>
              <a class="font-semibold hover:underline" target="_blank" href="https://platform.touchsms.com.au/purchases/">Click here</a>  to top up your account
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!insufficentCredits" class="my-4">
      <label class="inline-flex items-center text-sm mb-2">
        <input v-model="terms" type="checkbox" class="form-checkbox">
        <span class="ml-2 text-gray-600 text-xs">I agree to the {{ $branding.name }} <a class="text-blue-500 hover:text-blue-700" target="_blank" :href="$branding.termsUrl">Terms and Conditions</a></span>
      </label>
      <div class="flex items-center justify-between" v-if="!failed && !insufficentCredits">
        <div class="mr-2 inline-block w-full">
          <button @click="$emit('schedule')" :disabled="loading" type="button"
                  class="block w-full focus:outline-none text-white text-xs py-2 px-4 rounded-sm bg-warning-btn hover:bg-warning-btn-hvr hover:shadow-lg disabled:bg-warning-btn disabled:opacity-50">
            {{ this.schedule ? 'Edit Schedule' : 'Schedule SMS' }}
          </button>
        </div>
        <div class="mr-2 inline-block w-full">
          <button @click="$emit('send', processedMessages)" :disabled="loading|| !terms || processedMessages.length === 0" type="button"
                  class="block w-full focus:outline-none text-white text-xs py-2 px-5 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr disabled:bg-primary-btn disabled:opacity-50 ">
            {{ this.schedule ? 'Schedule SMS' : 'Send Now' }}
          </button>
        </div>
      </div>
      <div @click="$emit('back')" class="mr-2 mt-2">
        <button type="button"
                class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-5 rounded-sm bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100">
          Back to Editor
        </button>
      </div>
    </div>
    <div v-if="errorTypes">
      <p class="text-xs font-semibold text-gray-500 mb-4 underline">Excluded Numbers</p>
      <div class="flex justify-between" v-for="(count, code) in errorTypes" :key="code">
        <div class="text-gray-700 text-sm">{{ code }}</div>
        <div class="text-red-500 text-bold"> {{ count }}</div>
      </div>
    </div>
    <div class="mt-6">
      <p class="text-sm text-gray-600 mb-4">Message Sample Review</p>
      <template v-if="examples.length">
        <div class="border rounded-md text-sm p-2 mb-4" :key="msg.to" v-for="msg in examples">
          <p class="font-bold"><span class="text-xs text-gray-500 font-normal">To </span>{{ msg.to }}</p>
          <p class="whitespace-pre-line text-xs leading-3 mt-1">{{ msg.body }}</p>
        </div>
        <div class="flex justify-center items-center">
           <span class="inline-flex rounded-md shadow-sm ">
        <button @click="shuffleExamples" type="button"
                class="inline-flex items-center px-2 py-1 border border-gray-300 text-xs leading-4 font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
          Shuffle Samples
        </button>
      </span>
        </div>
      </template>
      <div v-else-if="processedMessages.length" class="flex justify-center items-center">
        <svg-loader class="h4 w-4 text-gray-600"></svg-loader>
      </div>
      <div v-else class="text-center text-gray-500 py-2 text-xs">
        No Valid Messages
      </div>
    </div>
  </div>
</template>

<script>
import SvgLoader from '@/components/Util/SvgLoader'
import { camelCase, chunk, groupBy, sampleSize, startCase, transform } from 'lodash'
import { Promise } from 'bluebird'
import moment from 'moment'

export default {
  name: 'Step2',
  components: { SvgLoader },
  props: {
    messages: Array,
    client: Object,
    removeDuplicates: Boolean,
    schedule: String,
  },
  data () {
    return {
      loading: true,
      failed: false,
      terms: false,
      insufficentCredits: false,
      tries: 1,
      stats: {
        totalParts: null,
        totalCredits: null,
        totalMessages: null,
      },
      errors: [],
      processedMessages: [],
      examples: [],
    }
  },
  methods: {
    async sendDry () {
      this.processedMessages = []
      this.errors = []

      let totalParts = 0,
          totalCredits = 0,
          totalMessages = 0,
          duplicateMessages = 0,
          errors = [],
          seenNumbers = []

      let messages = this.messages

      if (this.schedule) {
        messages = messages.map(msg => ({
          ...msg,
          date: this.schedule,
        }))
      }

      try {
        await Promise.map(chunk(messages, 1000), async msgChunk => {
          const res = await this.client.sendSmsDry(msgChunk)

          res.data.messages.map(msg => {
            if (this.removeDuplicates) {
              if (seenNumbers.includes(msg.to)) {
                res.data.errors.push({ error_code: 'DUPLICATE' })
                return
              }

              seenNumbers.push(msg.to)
            }

            totalParts += msg.meta.parts
            totalCredits += msg.meta.cost
            totalMessages += 1
            this.processedMessages.push(msg)
          })

          errors = errors.concat(res.data.errors)
          this.shuffleExamples()
        }, { concurrency: 3 })
      } catch (e) {
        // Handle timeouts/etc while calculating
        if (this.tries < 2) {
          this.tries++
          await this.sendDry()
          return
        }

        this.failed = true
      }

      const account = await this.client.getAccount()

      if (totalCredits > account.data.credits) {
        this.insufficentCredits = true
      }

      this.stats = { totalParts, totalCredits, totalMessages, duplicateMessages }
      this.errors = errors
      this.loading = false
    },
    shuffleExamples () {
      this.examples = sampleSize(this.processedMessages, 3)
    },
  },
  computed: {
    errorTypes () {
      if (!this.errors.length) {
        return
      }

      return transform(groupBy(this.errors, 'error_code'), (totals, errors, type) => {
        totals[startCase(camelCase(type))] = errors.length
      }, {})
    },
    scheduleFormatted () {
      if (!this.schedule) return null
      return moment(this.schedule).format('DD/MM/YYYY HH:mm')
    },
  },
  mounted () {
    this.sendDry()
  },
}
</script>

<style scoped>

</style>
