<template>
  <div>
    <p class="text-xs text-gray-700">Click & drag over cells to select your data range then click <b>Load</b>. Data from each selected column can be merged into your SMS.</p>
    <div class="text-sm mt-4">
      <div>
        <div v-if="changedWarning" class="rounded-md bg-blue-100 text-blue-500 text-sm p-4 my-4">
          We've detected you changed data in your sheet. Re-select your range and click the "Load" button to re-load the latest data
        </div>
        <div v-if="error" class="rounded-md bg-yellow-100 text-yellow-500 text-sm p-4 my-4">
          {{ error }}
        </div>
        <div v-if="!loadingData" class="mt-1 flex rounded text-sm">
          <button @click="updateColumns"
                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-l text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                  d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"/>
            </svg>
            <span class="ml-2">Load</span>
          </button>
          <div class="relative flex items-stretch flex-grow">
            <input v-model="range" readonly="readonly"
                   class="form-input form-input-sm block w-full rounded-none rounded-r transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                   placeholder="Select a Range and click Load">
          </div>
        </div>
        <div class="flex justify-center my-4" v-else>
          <div>
            <svg-loader class="h8 w-8 text-gray-600"></svg-loader>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <label class="block text-sm">
        <span class="text-gray-700">Column Containing Mobile Numbers</span>
        <select :disabled="!range" v-model="numberColumn" class="form-select form-select-sm mt-1 block w-full">
          <option v-for="number in columns" :key="number.value" :value="number.value">{{ number.name }}</option>
        </select>
      </label>
      <label class="inline-flex items-center text-sm mt-2">
        <input v-model="removeDuplicates" type="checkbox" class="form-checkbox" checked>
        <span class="ml-2 text-gray-600">Remove duplicate recipients</span>
      </label>
    </div>
    <div class="flex text-sm mt-4">
      <label class="w-full block">
        <span class="text-gray-700">Campaign Name</span>
        <input v-model="campaign" class="form-input form-input-sm mt-1 block w-full" placeholder="Optional">
      </label>
<!--      <label class="w-full block ml-4">-->
<!--        <span class="text-gray-700">Templates</span>-->
<!--        <select v-model="template" required class="form-select form-input-sm mt-1 block w-full">-->
<!--          <option disabled selected hidden :value="null">Select Template</option>-->
<!--          <option v-for="t in templates" :key="t.id" :value="t">{{ t.name }}</option>-->
<!--        </select>-->
<!--      </label>-->
    </div>
    <div class="mt-4">
      <label class="block text-sm">
        <span class="text-gray-700">From</span>
        <select v-model="senderId" class="form-select form-select-sm mt-1 block w-full">
          <option v-for="sender in senders" :key="sender.sender_id" :value="sender.sender_id">{{ sender.label }}</option>
        </select>
      </label>
    </div>
    <div class="mt-4">
      <div>
        <div class="h-8 rounded-t flex px-2 items-center justify-between bg-gray-100">
          <div class="text-gray-700 text-sm">Message</div>
          <div class="relative inline-block text-sm" v-on-clickaway="hideTemplateDropdown">
            <button @click="templateDropdown = !templateDropdown"
                    class="-ml-px relative inline-flex items-center rounded text-gray-700 hover:text-gray-500 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
              Templates
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
              </svg>
            </button>
            <div
                :class="`z-10 ${!templateDropdown ? 'hidden' : ''} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg transition ease-${templateDropdown ? 'out' : 'in'} duration-${templateDropdown ? '100' : '75'} transform opacity-${templateDropdown ? '100' : '0'} scale-${templateDropdown ? '100' : '95'}`">
              <div class="rounded-md bg-white shadow-xs">
                <div class="py-1 max-h-48 overflow-y-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button v-for="t in templates" :key="t.id" @click="applyTemplate(t)"
                          class="block w-full text-left px-3 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                          role="menuitem">
                    {{ t.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <textarea ref="message" v-model="message" class="form-textarea form-textarea-sm rounded-t-none block w-full" rows="6"
                  placeholder="Enter your message."></textarea>
      </div>
      <div class="relative pt-1">
        <div class="overflow-hidden h-1 mb-1 text-xs flex rounded bg-green-200">
          <div :style="{width: `${messageLength.inCurrentMessage / messageLength.characterPerMessage * 100}%`, transition:'width 0.5s ease-out'}"
               class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
        </div>
      </div>
      <div class="flex justify-between text-xs text-gray-600">
        <div>{{ messageLength.length }} Characters</div>
        <div>{{ messageLength.messages }} Message Part</div>
      </div>
      <div class="mt-4">
        <div class="text-gray-700 text-sm">Merge Fields</div>
        <div v-if="range" class="text-gray-600 text-xs">Data from each selected column can be merged into your SMS by clicking on the button(s) below.</div>
        <div v-else class="text-gray-500 text-xs mt-3 text-center">You haven't loaded any data</div>
        <div class="mt-2 flex flex-wrap justify-center">
          <button @click="insertMerge(col.value)" v-for="col in columns" :key="col.value"
                  class="mr-2 mt-1 text-green-700 bg-transparent border border-solid border-primary-btn text-primary-btn hover:bg-primary-btn hover:text-white active:bg-green-600 text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button">
            {{ col.name }}
          </button>
        </div>
      </div>
      <div class="mt-4 flex items-center justify-between flex-wrap">
        <div class="inline-block flex-none mr-1 mb-2" >
          <a :href="$branding.loginUrl" target="_blank"
                  class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-3 rounded-sm bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100">
            {{ $branding.loginButtonText }}
          </a>
        </div>
        <div @click="send" class="inline-block flex-none mb-2">
          <button type="button" class="focus:outline-none text-white text-xs py-2 px-3 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr">Preview
            Campaign
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { count } from 'sms-length'
import utils from '@/excel/util/excel'
import msgUtil from '@/excel/util/dataToMessage'
import SvgLoader from '@/components/Util/SvgLoader'
import moment from 'moment'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'Step1',
  components: { SvgLoader },
  props: {
    client: Object,
    account: Object,
  },
  mixins: [clickaway],
  data () {
    return {
      senders: [],
      templates: [],
      columns: [],
      data: null,
      error: null,
      loadingData: false,
      changedWarning: false,

      template: null,
      range: null,
      fistColumn: null,
      numberColumn: null,
      message: '',
      campaign: null,
      senderId: null,
      removeDuplicates: !this.account.settings.allow_sms_duplicates,
      templateDropdown: false,
    }
  },
  methods: {
    async loadSenders () {
      this.senders = (await this.client.getSenderIds()).data
      this.senderId = this.senders.find(s => s.default).sender_id
    },
    async loadTemplates () {
      this.templates = (await this.client.getTemplates()).data
    },
    applyTemplate (template) {
      const sender = this.senders.find(s => s.sender_id === template.sender_id)

      if (sender) {
        this.senderId = sender.sender_id
      }

      this.message = template.message
      this.hideTemplateDropdown()
      this.$refs.message.focus()
    },
    setData (data, firstCol) {
      const firstColIndex = utils.letterToColumn(firstCol)

      this.data = data

      this.columns = data[0].map((val, i) => {
        const col = utils.columnToLetter(firstColIndex + i)

        return { name: `Column ${col} ${val ? '(' + val + ')' : ''}`, value: col }
      })

      this.numberColumn = msgUtil.detectNumberCol(data, firstColIndex) || this.columns[0].value
      this.loadingData = false
    },
    async send () {
      if (!this.message) {
        this.error = 'You must enter a message'
        return
      }

      if (!this.range) {
        this.error = 'You must load some data'
        return
      }

      let campaign = this.campaign;

      if (!campaign) {
        const date = moment().format("DD/MM/YYYY HH:mm");

        campaign = `Excel Campaign (${date})`
      }

      const messages = msgUtil.rowsToSmsMessage(
          this.data,
          this.fistColumn,
          this.numberColumn,
          this.message,
          this.senderId,
          campaign,
      )

      this.$emit('preview', messages, this.removeDuplicates)
    },
    insertMerge (col) {
      this.message = this.message + `{{Column-${col}}}`
      this.$refs.message.focus()
    },
    handleChange (e) {
      if (!this.range) return

      window.Excel.run((context) => {
        // eslint-disable-next-line no-unused-vars
        let intRange
        const range = context.workbook.worksheets.getActiveWorksheet().getRange(e.address)
        range.load('address');

        return context.sync().then(() => {
          intRange = range.getIntersection(this.range)
        }).then(context.sync).then(() => {
          this.changedWarning = true;
        })
      })
    },
    bootChangeHandler () {
      window.Excel.run((context) => {
        const sheets = context.workbook.worksheets
        sheets.load('items/name')

        return context.sync().then(() => {
          sheets.items.forEach((sheet) => {
            sheet.onChanged.add(this.handleChange)
            console.log(`Change handler added for sheet ${sheet.name}`)
          })
        }).then(context.sync)
      })
    },
    updateColumns () {
      this.error = null
      this.range = null
      this.fistColumn = null
      this.data = null
      this.loadingData = true
      this.columns = null
      this.changedWarning = false

      window.Excel.run(context => {
        let range = context.workbook.getSelectedRange()
        let finalRange
        range.load('text')
        range.load('address')

        return context.sync().then(() => {
          if (range.text && range.text.length < 1) {
            this.error = 'You must select a range containing at least one row'
            this.loadingData = false
            return
          }

          const rangeParsed = utils.rangeToObject(range.address)

          if (rangeParsed.start.col === null) {
            this.error = 'You must select a range containing columns'
            this.loadingData = false
            return
          }

          // Handle unbounded row selection
          if (rangeParsed.start.row === null) {
            const sheet = context.workbook.worksheets.getActiveWorksheet()
            const usedRange = sheet.getUsedRange(true)
            usedRange.load('address')

            return context.sync().then(() => {
              const usedRangeParsed = utils.rangeToObject(usedRange.address)
              finalRange = sheet.getRange(`${rangeParsed.start.col}1:${rangeParsed.end.col}${usedRangeParsed.end.row + 50}`)
              finalRange.load('text')
              finalRange.load('address')
            }).then(context.sync).then(() => {
              const finalRangeParsed = utils.rangeToObject(finalRange.address)
              this.range = finalRangeParsed.range
              this.fistColumn = finalRangeParsed.start.col
              return this.setData(finalRange.text, this.fistColumn)
            })
          }

          this.range = rangeParsed.range
          this.fistColumn = rangeParsed.start.col
          return this.setData(range.text, rangeParsed.start.col)
        })
      }).catch(e => {
        console.log(e)
        this.error = 'There was an error loading the data you selected'
        this.loadingData = false
      })
    },
    reset () {
      this.message = ''
      this.campaign = null
      this.range = null
      this.fistColumn = null
      this.data = null
      this.columns = null
      this.changedWarning = false
    },
    hideTemplateDropdown () {
      this.templateDropdown = false
    },
  },
  watch: {
    template (template) {
      if (!template) return

      this.applyTemplate(template)
      this.template = null
    },
  },
  computed: {
    messageLength () {
      return count(this.message)
    },
  },
  mounted () {
    this.loadSenders()
    this.loadTemplates()
    this.bootChangeHandler()
  },
}
</script>

<style scoped>
select:invalid {
  color: #9fa6b2;
}
</style>
