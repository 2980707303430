<template>
  <div class="h-full">
    <template v-if="!modals">
      <div v-if="!isMobile || !currentThread" class="block flex-none mx-2 mb-4">
        <button @click="$emit('campaign')" type="button"
                class="w-full focus:outline-none text-white text-xs py-2 px-3 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr">
          Send Campaign
        </button>
      </div>
      <div v-if="!isMobile || !currentThread" class="h-full" style="height: calc(100vh - 210px)">
        <chat-actions
            class="mx-2"
            v-model="searchTerm"
            :searching="searching"
            @search="search"
            @clearSearch="loadConversations"
            @markAllRead="markAllRead"
            @new="addingThread = true"
            @logout="$emit('logout')"
        ></chat-actions>
        <number-list
            class="overflow-x-auto"
            :loading="loadingConversations || loadingSearch"
            :threads="threads"
            :currentThread="currentThread"
            @loadThread="loadMessagesPane"
        ></number-list>
      </div>
      <template v-if="!isMobile || currentThread">
        <chat-messages-header
            :is-mobile="isMobile"
            :currentThread="currentThread"
            @back="currentThread = null"
            @addContact="addContact"
        ></chat-messages-header>
        <div class="h-full" style="height: calc(100vh - 460px)">
          <chat-messages
              ref="messages"
              class="overflow-y-auto mb-4"
              :client="client"
              :isMobile="isMobile"
              :currentThread="currentThread"
              @messages="messages = $event"
              @loaded="markCurrentRead"
          ></chat-messages>
        </div>
        <send-chat-message v-if="currentNotAlpha"
                           :senderIds="senderIds"
                           :senderId="senderId"
                           :templates="templates"
                           :thread="currentThread"
                           :client="client"
                           @sent="$refs.messages.handleSent($event)"
        >
        </send-chat-message>
        <p class="text-gray-500 text-xs mx-4" v-else>
          You cannot reply to this message as it was sent to you using an Alphanumeric Sender Id which is not a valid mobile number
        </p>
      </template>
    </template>
    <template v-if="addingContact">
      <add-contact
          class="mx-2"
          @created="handleContactSave"
          @back="addingContact = false"
          :client="client"
          :currentThread="currentThread"
      ></add-contact>
    </template>
    <template v-if="addingThread">
      <new-thread
          class="mx-2"
          @thread="handleAddThread"
          @back="addingThread = false"
          :client="client"
      ></new-thread>
    </template>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import AddContact from '@/components/Outlook/Chat/Components/AddContact'
import NewThread from '@/components/Outlook/Chat/Components/NewThread'
import ChatActions from '@/components/Outlook/Chat/Components/ChatActions'
import NumberList from '@/components/Outlook/Chat/Components/NumberList'
import ChatMessages from '@/components/Outlook/Chat/Components/ChatMessages'
import ChatMessagesHeader from '@/components/Outlook/Chat/Components/ChatMessagesHeader'
import SendChatMessage from '@/components/Outlook/Chat/Components/SendChatMessage'
import { isNumericSource } from '@/outlook/util/chat'

export default {
  name: 'Chat',
  components: {
    SendChatMessage,
    ChatMessagesHeader,
    ChatMessages,
    NumberList,
    ChatActions,
    NewThread,
    AddContact,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      showNumber: null,
      senderIds: [],
      templates: [],
      searchTerm: null,
      searching: true,

      threads: [],
      conversationCursor: null,

      messages: [],
      messagesCursor: null,

      loadingSearch: false,
      loadingConversations: true,
      loadingMessages: true,

      currentThread: null,
      lastUnreadId: null,
      menuOpen: false,
      addingContact: false,
      addingThread: false,

      refreshInterval: null,
    }
  },
  props: {
    primaryBtnClass: String,
    secondaryBtnClass: String,
    highlightColor: String,
    client: Object,
    account: Object,
  },
  methods: {
    search () {
      if (!this.searchTerm) return

      this.searching = true
      this.loadingSearch = true
      this.client.searchConversations(this.searchTerm || '').then(data => {
        this.threads = data.data
        this.loadingSearch = false
        this.conversationCursor = null
      })
    },
    loadConversations (refresh = false, cursor = '') {
      this.searching = false
      this.searchTerm = null
      this.client.getConversations({ cursor: cursor || '' }).then(data => {
        this.threads = cursor
            ? this.threads.concat(data.data)
            : data.data

        this.conversationCursor = data.meta.cursor
        this.loadingConversations = false

        if (refresh && this.currentThread) {
          const thread = this.threads.find(t => t.remote === this.currentThread.remote)

          if (thread && thread.unread_messages > 0) {
            this.loadMessagesPane(thread, true)
            return
          }
        }

        if (this.threads.length && this.showNumber) {
          const thread = this.threads.find(t => t.remote === this.showNumber)

          if (thread) {
            this.loadMessagesPane(thread)
            return
          }

          this.$refs.addThread.withNumber(this.showNumber)
          return
        }

        if (!this.currentThread && this.threads.length && !this.isMobile) {
          this.loadMessagesPane(this.threads[0])
        }
      })
    },
    loadMessagesPane (thread, force = false) {
      if (this.currentThread && !force) {
        if (this.currentThread.id === thread.id || (this.$refs.send && !this.$refs.send.changeRecipient())) return
      }

      if (this.$refs.messages) {
        this.$refs.messages.reset()
      }

      this.currentThread = thread
      this.loadingMessages = false
    },
    loadMessages ($state) {
      this.client.getConversationMessages(this.currentThread.id, { cursor: this.messagesCursor || '' }).
          then((data) => {
            if (data.data.length) {
              this.messagesCursor = data.meta.cursor
              this.messages.unshift(...data.data.reverse())
              $state.loaded()
            } else {
              $state.complete()
            }
          })
    },
    markCurrentRead () {
      this.client.markConversationRead(this.currentThread.id).then(() => {
        this.threads = this.threads.map(t => {
          if (this.currentThread.id === t.id) {
            t.unread_messages = 0
          }

          return t
        })
      })
    },
    addContact () {
      this.addingContact = true
    },
    markAllRead () {
      this.client.markAllConversationsRead().then(() => this.loadConversations(false))
      this.menuOpen = false
    },
    handleContactSave (contact) {
      this.threads = this.threads.map(t => {
        if (t.remote === contact.number) {
          t.contact = contact
        }

        return t
      })

      this.currentThread.contact = contact
      this.addingContact = false
    },
    handleAddThread (thr) {
      this.threads.unshift(thr)
      this.loadMessagesPane(this.threads[0])
      this.addingThread = false
    },
    isNumericSource,
    handleNewMessages () {
      if (this.searching) return

      this.loadConversations(true)
    },
  },
  computed: {
    isMobile () {
      return this.$vssWidth <= 768
    },
    senderId () {
      for (let i = this.messages.length - 1; i > 0; i--) {
        const msg = this.messages[i]

        if (msg.direction === 'inbound') {
          return msg.to
        }

        if (msg.direction === 'outbound') {
          return msg.from
        }
      }

      return null
    },
    currentNotAlpha () {
      if (!this.currentThread) return false

      return isNumericSource(this.currentThread.remote)
    },
    modals () {
      return this.addingContact || this.addingThread
    },
  },
  created () {
    this.client.getSenderIds().then((data) => {
      this.senderIds = data.data
    })

    this.client.getTemplates().then((data) => {
      this.templates = data.data
    })

    this.loadConversations()

    if (!window.echo) {
      this.refreshInterval = setInterval(() => this.loadConversations(true), 15000)
      return
    }

    setTimeout(() => {
      window.echo.private(`user.${this.account.id}`).listen('InboundMessageEvent', () => this.handleNewMessages())
    }, 1000)
  },
  destroyed () {
    clearInterval(this.refreshInterval)
  },
}
</script>
