const config = {
  'touchsms': {
    'key': 'touchsms',
    'domain': 'outlookaddin.touchsms.com.an',
    'apiBase': 'https://app.touchsms.com.au/api/v2',
    'name': 'touchSMS',
    'logoTitle': 'touchSMS',
    'assetPrefix': '/assets/touchsms',

    'loginButtonText': 'Login to touchSMS',
    'loginButtonClass': 'primary-btn',
    'loginUrl': 'https://app.touchsms.com.au',
    'registerUrl': 'https://app.touchsms.com.au/register',

    'footerVersion': 'touchSMS for Outlook v1',
    'footerVersionUrl': 'https://app.touchsms.com.au',

    'platformBaseUrl': 'https://app.touchsms.com.au',
    'privacyUrl': 'https://www.touchsms.com.au/privacy-policy/',
    'termsUrl': 'https://www.touchsms.com.au/legal/',
    'pusherConfig' : {
      key: 'p8ab5ZrMsNPFg4sh3drc',
      wsHost: 'sockets.7efq5929n18jq.ap-southeast-2.cs.amazonlightsail.com',
      wsPort: '443',
      wssPort: '443',
    }
  },
  'edgility': {
    'key': 'edgility',
    'domain': 'outlookaddin.edgility.com.au',
    'apiBase': 'https://app.messageport.com.au/api/v2',
    'name': 'Edgility SMS',
    'logoTitle': 'Edgility SMS',
    'assetPrefix': '/assets/edgility',

    'loginButtonText': 'Login Online',
    'loginButtonClass': 'edgility-btn',
    'loginUrl': 'https://app.messageport.com.au',
    'registerUrl': 'https://sms.edgility.com.au/application/edgility',

    'footerVersion': 'Edgility SMS for Outlook v1',
    'footerVersionUrl': 'https://app.messageport.com.au',

    'platformBaseUrl': 'https://app.messageport.com.au',
    'privacyUrl': 'https://www.edgility.com.au/privacy-policy/',
    'termsUrl': 'https://www.edgility.com.au/legal/',
    'pusherConfig' : {
      key: 'gEb5249yWJKqzPQzPnvn',
      wsHost: 'sockets.7efq5929n18jq.ap-southeast-2.cs.amazonlightsail.com',
      wsPort: '443',
      wssPort: '443',
    }
  },
}

module.exports = config
