import excelBrandingConfig from './config/excel'
import outlookBrandingConfig from './config/outlook'

export default {
  install (Vue, options = {}) {
    const domain = options.domain || window.location.hostname

    let brandingConfig = outlookBrandingConfig
    let app = domain.includes('excel') ? 'excel' : 'outlook'

    if (app === 'excel') {
      brandingConfig = excelBrandingConfig
    }

    let branding = Object.values(brandingConfig).find(brand => brand.domain === domain)

    if (!branding) {
      branding = Object.values(brandingConfig)[0]
    }

    Vue.prototype.$app = app
    Vue.prototype.$branding = branding
    Vue.prototype.$assetPrefix = branding.assetPrefix
    Vue.prototype.$linkClass = `text-link-${branding.key} hover:text-link-${branding.key}-hvr`
  },
}
