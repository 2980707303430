<template>
  <div>
    <h3 class="font-semibold text-gray-700">Schedule SMS</h3>
    <div class="rounded-md bg-red-100 text-red-500 text-sm p-4 my-4" v-if="error">
      {{ error }}
    </div>
    <div class="rounded-md bg-blue-100 text-blue-500 text-sm p-4 my-4">
      <b>Current Time and Timezone</b>
      <br>{{ now }}
      <br>{{ currentTz }}
    </div>
    <div class="text-sm mt-4 w-full">
      <div class="text-gray-700 mb-2">Schedule Date & Time</div>
      <date-picker class="w-full" v-model="scheduleDate" type="datetime" placeholder="Select Date / Time" :show-second="false" value-type="date"
                   :disabled-date="dateCheck" @change="handleDateChange" :open.sync="dateOpen"></date-picker>
      <div class="text-xs text-gray-600 mt-2">Click the text box to select a date/time</div>
    </div>
    <div class="my-4">
      <div class="inline-block w-full">
        <button @click="submit" :disabled="!scheduleDate" type="button"
                class="block w-full focus:outline-none text-white text-xs py-2 px-5 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr disabled:bg-primary-btn disabled:opacity-50 ">
          Save Schedule
        </button>
      </div>
      <div v-if="oldSchedule" class="inline-block w-full mt-2">
        <button @click="$emit('remove')" :disabled="!scheduleDate" type="button"
                class="block w-full focus:outline-none text-white text-xs py-2 px-5 rounded-sm bg-warning-btn hover:bg-warning-btn-hvr disabled:bg-warning-btn disabled:opacity-50 ">
          Remove Schedule
        </button>
      </div>
      <div @click="$emit('back')" class="mt-2">
        <button type="button"
                class="block w-full focus:outline-none text-gray-700 text-xs py-2 px-5 rounded-sm bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100">
          Back to Preview
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
  name: 'Step3',
  components: {
    DatePicker,
  },
  props: {
    messages: Array,
    client: Object,
    tz: String,
    oldSchedule: String,
  },
  data () {
    return {
      scheduleDate: this.oldSchedule ? new Date(this.oldSchedule) : null,
      error: null,
      dateOpen: false,
    }
  },
  methods: {
    dateCheck (date) {
      return date <  moment().startOf('day').toDate();
    },
    submit() {
      this.error = null;
      if (this.scheduleDate < new Date()) {
        this.error = 'Date must not be in the past'
        return;
      }

      this.$emit('review', this.scheduleDateIso)
    },
    handleDateChange(value, type) {
      if (type === 'minute') {
        this.dateOpen = false;
      }
    }
  },
  computed: {
    now () {
      return moment().format('DD/MM/YYYY HH:mm')
    },
    scheduleDateIso () {
      // Safari has ms precision, which breaks ISO parsing in PHP
      return this.scheduleDate.toISOString().replace(/\.\d+Z/, 'Z')
    },
    currentTz () {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  },
}
</script>

<style scoped>

</style>
