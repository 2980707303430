<template>
  <div class="mx-2">
    <validation-errors :errors="errors"></validation-errors>
    <div class="flex text-sm">
      <div class="flex items-center w-full">
        <label class="block flex-grow text-xs">
          <span class="text-gray-700">From</span>
          <select v-model="sender" class="form-select form-select-sm mt-1 block w-full">
            <option v-for="sender in senderIds" :key="sender.sender_id" :value="sender.sender_id">{{ sender.label }}</option>
          </select>
        </label>
      </div>
    </div>
    <div class="mt-4">
      <div>
        <div class="h-8 rounded-t flex px-2 items-center justify-between bg-gray-100">
          <div class="text-gray-700 text-sm">Message</div>
          <div class="relative inline-block text-sm" v-on-clickaway="hideTemplateDropdown">
            <button @click="templateDropdown = !templateDropdown"
                    class="-ml-px relative inline-flex items-center rounded text-gray-700 hover:text-gray-500 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
              Templates
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
              </svg>
            </button>
            <div
                :class="`z-10 ${!templateDropdown ? 'hidden' : ''} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg transition ease-${templateDropdown ? 'out' : 'in'} duration-${templateDropdown ? '100' : '75'} transform opacity-${templateDropdown ? '100' : '0'} scale-${templateDropdown ? '100' : '95'}`">
              <div class="rounded-md bg-white shadow-xs">
                <div class="py-1 max-h-48 overflow-y-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button v-for="t in templates" :key="t.id" @click="applyTemplate(t)"
                          class="block w-full text-left px-3 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                          role="menuitem">
                    {{ t.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <textarea ref="message" v-model="body" class="form-textarea form-textarea-sm rounded-t-none block w-full" rows="4"
                  placeholder="Enter your message."></textarea>
      </div>
      <div class="relative pt-1 z-0">
        <div class="overflow-hidden h-1 mb-1 text-xs flex rounded bg-green-200">
          <div :style="{width: `${messageLength.inCurrentMessage / messageLength.characterPerMessage * 100}%`, transition:'width 0.5s ease-out'}"
               class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
        </div>
      </div>
      <div class="flex justify-between text-xs text-gray-600">
        <div>{{ messageLength.length }} Characters</div>
        <div>{{ messageLength.messages }} Message Part</div>
      </div>
    </div>
    <div @click="send" class="block flex-none mt-2">
      <button :disabled="sending" type="button" class="w-full focus:outline-none text-white text-xs py-2 px-3 rounded-sm bg-primary-btn hover:bg-primary-btn-hvr">
        Send Message
      </button>
    </div>
  </div>
</template>
<script>
import ValidationErrors from '@/components/Util/ValidationErrors'
import mergeUtil from '@/outlook/util/merge'
import { format } from 'date-fns'
import { count } from 'sms-length'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'SendChatMessage',
  components: { ValidationErrors },
  mixins: [clickaway],
  props: {
    thread: Object,
    senderIds: Array,
    templates: Array,
    senderId: String,
    client: Object,
  },
  data () {
    return {
      sender: null,
      body: '',
      template: null,
      errors: null,
      sending: false,
      templateDropdown: false,
    }
  },
  methods: {
    setDefaultSender () {
      const defaultSender = this.senderIds.find(s => s.default)

      if (defaultSender) {
        this.sender = defaultSender.sender_id
      }
    },
    changeRecipient () {
      if (!this.body) {
        return true
      }

      const change = window.confirm('If you leave close this chat you\'ll lose your draft message. Are you sure you want to switch recipients?')

      if (change) {
        this.body = null
      }

      return change
    },
    applyTemplate (template) {
      this.hideTemplateDropdown()
      const sender = this.senderIds.find(s => s.sender_id === template.sender_id)

      if (sender) {
        this.sender = sender.sender_id
      }

      this.body = template.message
      this.$refs.message.focus()
    },
    mergeBody () {
      if (!this.thread.contact) {
        return this.body
      }

      return mergeUtil.insertMergeFields(this.body, {
        'first name': this.thread.contact.firstname || '',
        'firstname': this.thread.contact.firstname || '',
        'surname': this.thread.contact.surname || '',
      })
    },
    send () {
      this.sending = true

      this.client.sendConversationMessage(this.thread.id, {
        from: this.sender,
        body: this.mergeBody(),
        campaign: `Outlook Chat ${format(new Date(), `yyyy-MM-dd HH:mm`)}`,
        source: 'outlook-chat',
        metadata: this.getMessageMeta()
      }).then(res => {
        this.errors = null
        this.body = ''
        this.sending = false
        this.$emit('sent', res.data)
      }).catch(e => {
        this.errors = e.response.data.errors
        this.sending = false
      })
    },
    hideTemplateDropdown () {
      this.templateDropdown = false
    },
    setSender () {
      if (this.sender !== null && this.sender === this.senderId) {
        return
      }

      const newSender = this.senderIds.find(s => s.sender_id === this.senderId)

      if (!newSender) {
        this.setDefaultSender()
        return
      }

      this.sender = newSender.sender_id
    },
    getMessageMeta() {
      const ref = window.localStorage.getItem('outlook-ref')

      return ref ? {reference: ref} : null
    }
  },
  computed: {
    messageLength () {
      return count(this.body)
    },
  },
  watch: {
    senderId () {
      this.setSender()
    },
    senderIds () {
      this.setDefaultSender()
    },
    template (template) {
      if (!template) return

      this.applyTemplate(template)
      this.template = null
    },
  },
  created () {
    this.setSender()
  },
}
</script>
