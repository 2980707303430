import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

const setupEcho = (config, apiBase, apiKey) => {
  const PusherClient = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY || config.key, {
    wsHost: process.env.VUE_APP_PUSHER_HOST || config.wsHost,
    wsPort: process.env.VUE_APP_PUSHER_PORT || config.wsPort,
    wssPort: process.env.VUE_APP_PUSHER_PORT || config.wssPort,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    channelAuthorization: {
      endpoint: `${apiBase}/auth/broadcast`,
      headers: { 'Authorization': 'Basic ' + btoa(apiKey.accessToken + ':' + apiKey.tokenId) },
    },
    authEndpoint: `${apiBase}/auth/broadcast`,
    auth: {
      headers: { 'Authorization': 'Basic ' + btoa(apiKey.accessToken + ':' + apiKey.tokenId) },
    },
  })

  window.echo = new Echo({
    broadcaster: 'pusher',
    client: PusherClient,
  })
}

export { setupEcho }
