import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import Branding from './branding/plugin'
import VueTippy, { TippyComponent } from 'vue-tippy'
import linkify from 'vue-linkify'
import VueCompositionAPI from '@vue/composition-api'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import './assets/tailwind.css'
// import './assets/css/bootstrap.min.css'

Vue.config.productionTip = false
Vue.use(Branding, {
  domain: process.env.VUE_APP_LOCAL_DOMAIN,
})

Vue.use(VueCompositionAPI)
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);
Vue.directive('linkified', linkify)

if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: '02726530ca073098820fa652a2bb39f4',
    plugins: [new BugsnagPluginVue()]
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

window.Office.initialize = () => {
  const vue = new Vue({
    render: h => h(App),
  }).$mount('#app')

  if (window.Office.context.mailbox) {
    window.Office.context.mailbox.addHandlerAsync(window.Office.EventType.ItemChanged, () => {
      vue.$emit('item-change')
    })
  }
};
