const isAlphaSource = (number) => {
  return !isNumericSource(number)
}
const isNumericSource = (number) => {
  return number.match(/^\d{5,20}$/)
}

const validContact = (contact) => {
  return contact && (contact.firstname || contact.surname)
}

export { isAlphaSource, isNumericSource, validContact }
