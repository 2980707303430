const insertMergeFields = (message, data) => {
  Object.keys(data).forEach(key => {
    message = message.replace(`{{${key}}}`, data[key])
  })

  return message.replace(/{{.*?}}/g, '')
}


export default {insertMergeFields}
