const columnToLetter = (column) => {
  let temp, letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

const letterToColumn = (letter) => {
  let column = 0, length = letter.length
  for (let i = 0; i < length; i++) {
    column += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1)
  }
  return column
}

const rangeToObject = (rangeStr) => {
  let regex = /^'?(?:(.*?)?'?!)?(([A-Z]{0,3})?(\d+)?:?([A-Z]{0,3})?(\d+)?)$/

  let match = regex.exec(rangeStr)

  return {
    sheet: match[1] || null,
    range: match[2] || null,
    start : {
      col: match[3] || null,
      row: match[4] || null,
    },
    end : {
      col: match[5] || null,
      row: match[6] || null,
    },
  }

}

export default { columnToLetter, letterToColumn, rangeToObject }
