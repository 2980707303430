import excel from './excel'

const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/

const checkPhoneNumber = (number) => {
  return  phoneRegex.test(number)
}

const likelyIsPhoneNumber = (number) => {
  return (number.match(/\d/g) || []).length > 5
}

const insertMergeFields = (message, row, baseIndex) => {
  const totalCells = row.length
  for (let i = 0; i < totalCells; i++ ) {
    const letter = excel.columnToLetter(baseIndex + i)
    message = message.replace(`{{Column-${letter}}}`, row[i])
  }

  return message.replace(/{{.*?}}/g, '')
}

const detectNumberCol = (data, firstColumnIndex) => {
  for (let i = 0; i < data.length && i < 10; i++) {
    const row = data[i]
    for (let i = 0; i < row.length; i++) {
      if (likelyIsPhoneNumber(row[i])) {
        return excel.columnToLetter(firstColumnIndex + i)
      }
    }
  }
}

const rowsToSmsMessage = (data, firstColumn, senderColumn, message, sender, campaign) => {
  const baseIndex = excel.letterToColumn(firstColumn)
  const numberColIndex = excel.letterToColumn(senderColumn) - baseIndex
  const totalRows = data.length
  const shouldMergeMessages = /{{.*?}}/.test(message)

  let messages = []

  for (let i = 0; i < totalRows; i++) {
    const row = data[i]
    const number = row[numberColIndex]

    if (!number || !checkPhoneNumber(number)) {
      continue;
    }

    let rowMessage = message

    if (shouldMergeMessages) {
      rowMessage = insertMergeFields(rowMessage, row, baseIndex)
    }

    messages.push({
      to: number,
      from: sender,
      body: rowMessage,
      campaign: campaign || null,
      source: 'excel'
    })
  }

  return messages
}

export default { rowsToSmsMessage, detectNumberCol }
