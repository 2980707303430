<template>
  <div class="h-full pt-4">
    <div v-if="loading" class="loader">
      <i class="fa fa-spin fa-refresh"></i>
    </div>

    <ul v-else-if="threads.length" class="divide-y divide-gray-200">
      <li
          @click="$emit('loadThread', thread)"
          v-for="thread in threads"
          :key="thread.id"
          :class="{'bg-gray-200': currentThread && thread.remote === currentThread.remote}"
          :style="currentThread && thread.remote === currentThread.remote ? `border-left: red solid 5px;` : ''"
          class="px-2 py-3 space-x-3 hover:bg-gray-100 cursor-pointer">
        <div class="flex justify-between text-sm">

          <div>
            <span v-if="thread.contact && validContact(thread.contact)"><span class="contact-name">{{ thread.contact.firstname }} {{ thread.contact.surname }}</span></span>
            <span class="number" v-else>{{ isNumericSource(thread.remote) ? '+' : '' }}{{ thread.remote }}</span>
          </div>
          <div class="flex items-center">
            <svg v-if="thread.unread_messages" class="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3"/>
            </svg>
            <span v-if="thread.last_message_at" class="sent-at pull-right">{{ formatDateConvo(thread.last_message_at) }}</span>
          </div>
        </div>
      </li>
    </ul>
    <div class="text-center text" v-else>
      <div class="m-t-10">No Results. Please go to <a href="/reports/?rangereports[report]=single">detailed reports</a> to view more.</div>
    </div>
  </div>
</template>

<script>
import { format, isSameWeek, isToday, parseISO } from 'date-fns'
import { isNumericSource, validContact } from '@/outlook/util/chat'

export default {
  name: 'NumberList',
  props: {
    loading: Boolean,
    threads: Array,
    currentThread: Object,
  },
  methods: {
    formatDateConvo (date) {
      const now = new Date()
      date = parseISO(date)

      if (isToday(date)) {
        return format(date, 'h:mm aaa')
      }

      if (isSameWeek(date, now)) {
        return format(date, 'E h:mm aaa')
      }

      return format(date, 'd MMM')
    },
    isNumericSource,
    validContact,
  },
}
</script>

<style scoped>

</style>
