const config = {
  'touchsms': {
    'key': 'touchsms',
    'domain': 'excel.touchsms.com.au',
    'apiBase': 'https://app.touchsms.com.au/api/v2',
    'name': 'touchSMS',
    'logoTitle': 'touchSMS for Excel',
    'assetPrefix': '/assets/touchsms',

    'loginButtonText': 'Login to touchSMS',
    'loginButtonClass': 'primary-btn',
    'loginUrl': 'https://app.touchsms.com.au',
    'registerUrl': 'https://app.touchsms.com.au/register',

    'footerVersion': 'touchSMS for Excel v1',
    'footerVersionUrl': 'https://app.touchsms.com.au',

    'platformBaseUrl': 'https://app.touchsms.com.au',
    'privacyUrl': 'https://www.touchsms.com.au/privacy-policy/',
    'termsUrl': 'https://www.touchsms.com.au/legal/',
  },
  'edgility': {
    'key': 'edgility',
    'domain': 'excel.edgility.com.au',
    'apiBase': 'https://app.messageport.com.au/api/v2',
    'name': 'Edgility SMS',
    'logoTitle': 'Edgility SMS for Excel',
    'assetPrefix': '/assets/edgility',

    'loginButtonText': 'Login Online',
    'loginButtonClass': 'edgility-btn',
    'loginUrl': 'https://app.messageport.com.au',
    'registerUrl': 'http://sms.edgility.com.au/application/edgility',

    'footerVersion': 'Edgility SMS for Excel v1',
    'footerVersionUrl': 'https://app.messageport.com.au',

    'platformBaseUrl': 'https://app.messageport.com.au',
    'privacyUrl': 'https://www.edgility.com.au/privacy-policy/',
    'termsUrl': 'https://www.edgility.com.au/legal/',
  },
}

module.exports = config
