<template>
  <div class="px-6 py-4">
    <step1 ref="step1" :class="{hidden: step !== 1}" @preview="preview" :client="client" :account="account"></step1>
    <step2 v-if="step === 2"
           @back="messages = null;step = 1;schedule=null"
           @send="messages = $event;step=4"
           @schedule="step=3"
           :client="client"
           :messages="messages"
           :schedule="schedule"
           :removeDuplicates="removeDuplicates"
    ></step2>
    <step3 v-if="step === 3"
           @review="step=2;schedule=$event"
           @remove="step=2;schedule=null"
           @back="step=2"
           :tz="account.settings.timezone"
           :messages="messages"
           :old-schedule="schedule"
    ></step3>
    <Step4 v-if="step === 4" @back="reset" :client="client" :messages="messages" :removeDuplicates="removeDuplicates"></Step4>
  </div>
</template>
<script>


import Step1 from '@/components/Excel/Send/Step1'
import Step2 from '@/components/Excel/Send/Step2'
import Step3 from '@/components/Excel/Send/Step3'
import Step4 from '@/components/Excel/Send/Step4'

export default {
  name: 'Send',
  components: { Step3, Step4, Step2, Step1 },
  props: {
    client: Object,
    account: Object,
  },
  data () {
    return {
      step: 1,
      messages: null,
      schedule: null,
      removeDuplicates: true,
    }
  },
  methods: {
    reset () {
      this.$refs.step1.reset()
      this.$nextTick(() => {
        this.schedule = null
        this.messages = null
        this.step = 1
      })
    },
    preview (messages, removeDuplicates) {
      this.messages = messages
      this.step = 2
      this.removeDuplicates = removeDuplicates
    },
  },
}
</script>

<style scoped>
select:invalid {
  color: #9fa6b2;
}
</style>
